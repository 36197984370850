import {Injectable, Type} from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ActivatedRouteSnapshot, ResolveEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Destroyed} from '../components/shared/directives/destroyed.directive';

@Injectable()
export class ApplicationInsightsService extends Destroyed {
  private readonly appInsights: ApplicationInsights;

  constructor(private readonly router: Router) {
    super();

    if (environment.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.instrumentationKey
        }
      });

      this.appInsights.loadAppInsights();
      this.router.events
        .pipe(
          this.untilDestroyed(),
          filter((event) => event instanceof ResolveEnd)
        )
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(
              `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
              event.urlAfterRedirects
            );
          }
        });
    }
  }

  setUserId(userId: string) {
    if (environment.instrumentationKey) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  clearUserId() {
    if (environment.instrumentationKey) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({name, uri});
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): Type<unknown> {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
