import {Injectable} from '@angular/core';
import {ApplicationInsightsService} from '../application-insights.service';

@Injectable({
  providedIn: 'root'
})
export class AuthWebservice {
  token: string;
  isAuth = false;
  isAuthBDD = false;

  constructor(private readonly applicationInsightsService: ApplicationInsightsService) {}

  signOutUser() {
    localStorage.clear();
    sessionStorage.clear();
    this.token = null;
    this.isAuth = false;
    this.isAuthBDD = false;
    this.applicationInsightsService.clearUserId();
  }
}
