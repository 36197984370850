import {Component, OnInit} from '@angular/core';
import {LogoutWebservice} from '../../../services/webservices/logout.webservice';
import {ShowSidebarService} from '../../../services/show-sidebar.service';
import {User} from '../../../model/user/user.model';
import {ProgramWebservice} from '../../../services/webservices/program.webservice';
import {Destroyed} from '../directives/destroyed.directive';
import {environment} from '../../../../environments/environment';
import {ExportWebservice} from '../../../services/webservices/export.webservice';
import {CurrentUserService} from '../../../services/current-user.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {NgClass, NgIf} from '@angular/common';
import {ClickOutsideDirective} from '../directives/click-outside.directive';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    ClickOutsideDirective,
    NgClass,
    NgIf,
    RouterLink,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    MatProgressSpinnerModule
  ]
})
export class SidebarComponent extends Destroyed implements OnInit {
  currentUser: User;
  programs = [];
  isSending: boolean = false;
  version;

  constructor(
    private readonly currentUserService: CurrentUserService,
    public readonly logoutService: LogoutWebservice,
    public readonly showSidebarService: ShowSidebarService,
    private readonly programWebservice: ProgramWebservice,
    private readonly exportWebService: ExportWebservice
  ) {
    super();
    this.version = environment.appVersion === '' ? 'ALPHA' : environment.appVersion;
  }

  ngOnInit() {
    this.currentUser = this.currentUserService.currentUser;
    this.programWebservice
      .getPrograms()
      .pipe(this.untilDestroyed())
      .subscribe((value) => (this.programs = value));
  }

  expandClose() {
    this.showSidebarService.showSidebar = !this.showSidebarService.showSidebar;
  }

  closeSideBar() {
    this.showSidebarService.showSidebar = false;
  }

  canAccess(path: string): boolean {
    return this.currentUserService.canAccess(path);
  }

  isActif(id) {
    return this.programs.some((item) => item.id === id);
  }

  sendMail() {
    if (!this.isSending) {
      this.isSending = true;
      this.exportWebService
        .getMailRi()
        .pipe(this.untilDestroyed())
        .subscribe(() => (this.isSending = false));
    }
  }
}
