import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatDialog} from '@angular/material/dialog';
import {SelectMultipleTreeComponent} from 'src/app/components/shared/form-parts/form-items/select-multiple-tree/select-multiple-tree.component';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {TreeContentType, TreeDataService} from 'src/app/services/tree-data.service';
import {FiltersService} from 'src/app/services/filters.service';
import {FormatService} from 'src/app/services/format.service';
import {TreeItemFlatNode} from 'src/app/model/event/treeData/tree-item-flat-node.model';
import {SearchWebservice} from 'src/app/services/webservices/search.webservice';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {AutocompleteItemPlace} from 'src/app/model/event/place/autocomplete-item-place';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {AsyncPipe, DatePipe, NgFor, NgIf, TitleCasePipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {isNotNullOrUndefined} from 'src/app/utils/utils.static';

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatIconModule,
    NgFor,
    MatOptionModule,
    MatChipsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    AsyncPipe,
    TitleCasePipe,
    DatePipe
  ]
})
export class HeaderDashboardComponent extends Destroyed implements OnInit {
  @Output() dateChange = new EventEmitter<Date>();
  @Output() choixPlaceChange = new EventEmitter<TreeItemFlatNode[]>();
  @Input() maxDate: Date;
  @Input() minDate: Date;

  displayDate: Date = new Date();
  inputTreeControl: UntypedFormControl;
  choixPlaceList: TreeItemFlatNode[];
  choixPlaceNameList: string[] = [];

  /**
   * chip list place
   */
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allChildPlace: AutocompleteItemPlace[] = [];
  filteredPlaceList: Observable<AutocompleteItemPlace[]>;
  @ViewChild('centerInput') centerInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly treeDataService: TreeDataService,
    private readonly filtersService: FiltersService,
    public readonly formatService: FormatService,
    private readonly searchWebservice: SearchWebservice
  ) {
    super();
  }

  ngOnInit(): void {
    this.inputTreeControl = new UntypedFormControl();

    this.searchWebservice
      .getActivePlacesForCurrentUser()
      .pipe(this.untilDestroyed())
      .subscribe((data: TreeItemNode[]) => {
        this.treeDataService.currentContentType = TreeContentType.PLACE;
        this.treeDataService.changeAllPlace(data);
        if (this.choixPlaceList.length !== 0) {
          this.inputTreeControl.setValue(data[0].name);
        }

        this.getChildPlaces(data);
        this.filteredPlaceList = this.inputTreeControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterFullPlaceList(value))
        );

        if (this.filtersService.hasMultipleFilter('placeDashboard')) {
          this.choixPlaceNameList = this.filtersService.getMultipleFilter('placeDashboard');
          this.getChoixPlaceFromChoixPlaceName();
        }
      });

    this.treeDataService.choixPlacesMessagerDashboard
      .pipe(this.untilDestroyed())
      .subscribe((data: TreeItemFlatNode[]) => {
        this.choixPlaceList = data;
        this.choixPlaceNameList = [];

        // set place in input for the user
        this.choixPlaceList.forEach((place) => {
          if (!place.expandable) {
            this.choixPlaceNameList.push(place.name);
          }
        });
        this.filtersService.setMultipleFilter('placeDashboard', this.choixPlaceNameList);
        this.choixPlaceChange.emit(this.choixPlaceList);
      });
  }

  // L'année et le mois est choisie
  onMonthSelect(event: Date, picker: MatDatepicker<Date>) {
    this.displayDate = event;
    this.dateChange.emit(event);
    picker.close();
  }

  goToNextMonth() {
    const newDate = new Date(this.displayDate);
    if (newDate.getMonth() === 12) {
      newDate.setMonth(1);
      newDate.setFullYear(newDate.getFullYear() + 1);
    } else {
      newDate.setMonth(newDate.getMonth() + 1);
    }

    if (
      !this.maxDate ||
      (this.maxDate && this.maxDate.getFullYear() > newDate.getFullYear()) ||
      (this.maxDate.getFullYear() === newDate.getFullYear() &&
        newDate.getMonth() <= this.maxDate.getMonth())
    ) {
      this.setDisplayDate(newDate);
    }
  }

  goToLastMonth() {
    const newDate = new Date(this.displayDate);
    if (newDate.getMonth() === 1) {
      newDate.setMonth(12);
      newDate.setFullYear(newDate.getFullYear() - 1);
    } else {
      newDate.setMonth(newDate.getMonth() - 1);
    }

    if (
      !this.minDate ||
      (this.minDate && this.minDate.getFullYear() < newDate.getFullYear()) ||
      (this.minDate.getFullYear() === newDate.getFullYear() &&
        newDate.getMonth() >= this.minDate.getMonth())
    ) {
      this.setDisplayDate(newDate);
    }
  }

  openModal() {
    this.dialog.open(SelectMultipleTreeComponent, {
      panelClass: 'dialog'
    });
  }

  // place chip list
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.choixPlaceNameList.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.inputTreeControl.setValue('');
    this.centerInput.nativeElement.value = '';
    this.treeDataService.changeChoixPlaceDashboard(this.choixPlaceList);
  }

  remove(placeName: string): void {
    const index = this.choixPlaceNameList.indexOf(placeName);

    if (index >= 0) {
      this.choixPlaceNameList.splice(index, 1);
    }

    this.getChoixPlaceFromChoixPlaceName();
    const allChildPlaceIndex = this.allChildPlace.findIndex(
      (item) => item.place.name === placeName
    );
    if (allChildPlaceIndex) {
      this.allChildPlace[allChildPlaceIndex].isActive = true;
    }

    this.inputTreeControl.setValue('');
    this.centerInput.nativeElement.value = '';
    this.treeDataService.changeChoixPlaceDashboard(this.choixPlaceList);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.choixPlaceNameList.push(event.option.viewValue);
    this.inputTreeControl.setValue(null);

    this.getChoixPlaceFromChoixPlaceName();
    const index = this.allChildPlace.findIndex(
      (item) => item.place.name === event.option.viewValue
    );
    if (index) {
      this.allChildPlace[index].isActive = false;
    }

    this.inputTreeControl.setValue('');
    this.centerInput.nativeElement.value = '';
    this.treeDataService.changeChoixPlaceDashboard(this.choixPlaceList);
  }

  getChoixPlaceFromChoixPlaceName() {
    this.choixPlaceList = [];
    this.choixPlaceNameList.forEach((name) => {
      const search = this.allChildPlace.find((item) => item.place.name === name);
      const flat = new TreeItemFlatNode();
      flat.name = search.place.name;
      flat.id = search.place.id;

      this.choixPlaceList.push(flat);
    });
  }

  getChildPlaces(placeList: TreeItemNode[]) {
    placeList.forEach((place) => {
      this.allChildPlace.push({place, isActive: true});
      if (isNotNullOrUndefined(place.listOfChilds)) {
        this.getChildPlaces(place.listOfChilds);
      }
    });
  }

  private setDisplayDate(newDate: Date) {
    this.displayDate = newDate;
    this.dateChange.emit(newDate);
  }

  private _filterFullPlaceList(value: string): AutocompleteItemPlace[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.allChildPlace.filter(
        (item) =>
          item.place.name.toLowerCase().includes(filterValue) &&
          !item.place.listOfChilds &&
          item.isActive
      );
    } else {
      return [];
    }
  }
}
