<div *ngIf="type === 0 || type === 1" class="d-flex flex-row flex-nowrap align-items-center p-2">
  <div *ngIf="type === 0" class="chart">
    <canvas [type]="chartType"
            [colors]="barChartColors"
            [datasets]="barChartData"
            [options]="barChartOptions"
            baseChart
    ></canvas>
  </div>
  <div *ngIf="type === 1" [ngStyle]="{backgroundColor: backgroudColor}" class="item-logo">
    <img *ngIf="logoPath" [src]="logoPath" alt="" class="d-flex flex-wrap align-items-center">
  </div>
  <div class="content d-flex flex-column justify-content-center">
    <p class="item-label" title="{{item.label}}">{{item.label}}</p>
    <div class="d-flex flex-row align-items-center">
      <h4 *ngIf="!item.isPercentage" class="item-value">{{item.displayValue}}</h4>
      <h4 *ngIf="item.isPercentage" class="item-value">{{item.displayValue + '%'}}</h4>
      <p class="my-0 mx-2">
        <span *ngIf="item.progress" [ngClass]="{'bad-progress': (item.progress && item.progress < 0)}" class="progress">
          <span *ngIf="item.progress > 0">+</span>{{item.progress}}%
        </span>
      </p>
    </div>
  </div>
</div>
<div *ngIf="type === 2" class="d-flex flex-column align-items-stretch inline-item p-2">
  <div class="d-flex flex-row flex-nowrap justify-content-between">
    <p class="item-label" title="{{item.label}}">{{item.label}}</p>
    <h4 class="item-value">{{item.displayValue}}</h4>
  </div>
  <div class="graph-bar d-flex flex-row">
    <div *ngFor="let value of inlineGraphDisplay" [ngStyle]="{'background-color': value.color, 'width': value.value}"
         class="part-graph"></div>
  </div>
  <div class="d-flex flex-row flex-nowrap justify-content-between py-1">
    <p class="item-label right">{{item.leftLabel}}</p>
    <h4 class="item-label left">{{item.rightLabel}}</h4>
  </div>
</div>
