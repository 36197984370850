import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class LoadingService {
  loadingStatus: Subject<boolean> = new Subject();

  private _loading = true;

  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    if (this._loading !== value) {
      this._loading = value;
      this.loadingStatus.next(value);
    }
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}
