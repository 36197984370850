import {AuthWebservice} from './auth.webservice';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {SessionService} from '../session.service';
import {CurrentUserService} from '../current-user.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutWebservice {
  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly authService: AuthWebservice,
    private readonly sessionService: SessionService,
    private readonly router: Router
  ) {}

  logout() {
    this.currentUserService.currentUser = null;
    this.authService.signOutUser();
    this.sessionService.reset();
    this.router.navigate(['']);
  }
}
