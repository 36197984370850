import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import {environment} from 'src/environments/environment';
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azure.activeDirectory.clientId,
      authority: environment.azure.activeDirectory.authority,
      redirectUri: environment.azure.activeDirectory.redirectUri,
      postLogoutRedirectUri: environment.azure.activeDirectory.postLogoutRedirectUri,
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap
    .set(environment.envApiUrl, [environment.azure.activeDirectory.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.azure.activeDirectory.scope]
    },
    loginFailedRoute: environment.azure.activeDirectory.postLogoutRedirectUri
  };
}
