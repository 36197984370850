<div (clickOutsideMenu)="closeSideBar()" class="sidebar" clickOutsideMenu>

  <div class="scroll_menu">
    <div
      [ngClass]="[this.showSidebarService.showSidebar ? 'expand':'short', showSidebarService.showSidebar ? 'showOnMobile' : '' ]"
      class="sidebar_content">

      <div (click)="expandClose()" class="icon_holder">
        <img alt="" class="icon" src="assets/images/menu_icon.svg">
      </div>

      <div [ngClass]="showSidebarService.showSidebar ? 'el_menu_expand':'el_menu_short'"
           class="d-flex justify-content-center align-items-center el_user my-2 el_border_bottom el_border_top">
        <div class="user_items d-flex flex-column justify-content-center align-items-center">
          <div *ngIf="currentUser" [ngClass]="showSidebarService.showSidebar ? 'user_item_show':'user_item_hide'"
               class="user_text">
            {{currentUser.firstName}} {{currentUser.lastName}}
          </div>
          <div *ngIf="currentUser" [ngClass]="showSidebarService.showSidebar ? 'user_item_hide':'user_item_show'"
               class="user_text">
            {{currentUser.firstName[0]}} {{currentUser.lastName[0]}}
          </div>
          <button (click)="logoutService.logout()"
                  [ngClass]="showSidebarService.showSidebar ? 'user_item_show':'user_item_hide'"
                  class="btn btn-danger"
                  type="button">Se déconnecter
          </button>
        </div>

      </div>

      <a (click)="closeSideBar()" *ngIf="canAccess('dashboard')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./dashboard">
        <mat-icon class="icon_menu" matTooltip="Tableau de bord" matTooltipPosition="right">dashboard</mat-icon>
        <div class="menu_text h-100">Tableau de bord</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         [queryParams]="{ canLogin: true }" class="el_link"
         routerLink="./profile-young">
        <mat-icon class="icon_menu" matTooltip="Liste des inscrits" matTooltipPosition="right">people</mat-icon>
        <div class="menu_text h-100">Liste des inscrits</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./young-creation">
        <mat-icon class="icon_menu" matTooltip="Inscrire un jeune" matTooltipPosition="right">person_add</mat-icon>
        <div class="menu_text h-100">Nouvel inscrit</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./my-events">
        <mat-icon class="icon_menu" matTooltip="Liste des événements" matTooltipPosition="right">event</mat-icon>
        <div class="menu_text h-100">Liste des événements</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./event-creation">
        <mat-icon class="icon_menu" matTooltip="Créer un événement" matTooltipPosition="right">edit_calendar</mat-icon>
        <div class="menu_text h-100">Créer un événement</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('export')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./export">
        <mat-icon class="icon_menu" matTooltip="Exports" matTooltipPosition="right">description</mat-icon>
        <div class="menu_text h-100">Export</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('import')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./import">
        <mat-icon class="icon_menu" matTooltip="Imports" matTooltipPosition="right">upload_file</mat-icon>
        <span class="menu_text h-100">Import</span>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('young-fusion')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./young-fusion">
        <mat-icon class="icon_menu" matTooltip="Fusion" matTooltipPosition="right">call_merge</mat-icon>
        <div class="menu_text h-100">Fusion</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('family')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./family-setting">
        <mat-icon class="icon_menu" matTooltip="Familles" matTooltipPosition="right">family_restroom</mat-icon>
        <div class="menu_text h-100">Familles</div>
      </a>


      <a (click)="closeSideBar()" *ngIf="canAccess('speaker')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./speaker">
        <mat-icon class="icon_menu" matTooltip="Liste des intervenants" matTooltipPosition="right">supervisor_account
        </mat-icon>
        <div class="menu_text h-100">Intervenants</div>
      </a>


      <a (click)="closeSideBar()" *ngIf="canAccess('structures')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./structures">
        <mat-icon class="icon_menu" matTooltip="Liste des structures" matTooltipPosition="right">business</mat-icon>
        <div class="menu_text h-100">Structure</div>
      </a>


      <a (click)="closeSideBar()" *ngIf="canAccess('reporting')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./reporting">
        <mat-icon class="icon_menu" matTooltip="Reporting" matTooltipPosition="right">insights</mat-icon>
        <div class="menu_text h-100">Reporting</div>
      </a>

      <a (click)="closeSideBar()" *ngIf="canAccess('depot-document')"
         [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
         class="el_link"
         routerLink="./depot-document">
        <mat-icon class="icon_menu" matTooltip="Dépot de document" matTooltipPosition="right">inventory_2</mat-icon>
        <div class="menu_text h-100">Dépot de document</div>
      </a>
      <hr *ngIf="!showSidebarService.showSidebar">


      <mat-accordion *ngIf="showSidebarService.showSidebar">

        <!-- SDLV pannel -->
        <mat-expansion-panel *ngIf="isActif(1)" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Sport dans la Ville
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 1, canLogin: true}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">Liste des inscrits</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 1, canLogin: false}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">En attente de validation</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 1}"
             class="el_link"
             routerLink="./young-creation">
            <mat-icon class="icon_menu">person_add</mat-icon>
            <div class="menu_text h-100">Nouvel inscrit</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 1}"
             class="el_link"
             routerLink="./my-events">
            <img alt="" class="icon_menu" src="../../../../assets/images/inscrit_menu.svg">
            <div class="menu_text h-100">Liste des événements</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 1}" class="el_link"
             routerLink="./event-creation">
            <img alt="" class="icon_menu" src="../../../../assets/images/calendar.svg">
            <div class="menu_text h-100">Créer un événement</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('file-manager')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./file-manager/document/1">
            <img alt="" class="icon_menu" src="../../../../assets/images/folder.svg">
            <div class="menu_text h-100">Voir les documents</div>
          </a>
        </mat-expansion-panel>

        <!-- Réussite dans la Ville pannel -->
        <mat-expansion-panel *ngIf="isActif(2)" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Réussite dans la Ville
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 2, canLogin: true}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">Liste des inscrits</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 2}"
             class="el_link"
             routerLink="./young-creation">
            <mat-icon class="icon_menu">person_add</mat-icon>
            <div class="menu_text h-100">Nouvel inscrit</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 2}"
             class="el_link"
             routerLink="./my-events">
            <img alt="" class="icon_menu" src="../../../../assets/images/inscrit_menu.svg">
            <div class="menu_text h-100">Liste des événements</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 2}" class="el_link"
             routerLink="./event-creation">
            <img alt="" class="icon_menu" src="../../../../assets/images/calendar.svg">
            <div class="menu_text h-100">Créer un événement</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('file-manager')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./file-manager/document/2">
            <img alt="" class="icon_menu" src="../../../../assets/images/folder.svg">
            <div class="menu_text h-100">Voir les documents</div>
          </a>
        </mat-expansion-panel>

        <!-- JDLV pannel -->
        <mat-expansion-panel *ngIf="isActif(5)" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Job dans la Ville
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 5, canLogin: true}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">Liste des inscrits</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 5}"
             class="el_link"
             routerLink="./young-creation">
            <mat-icon class="icon_menu">person_add</mat-icon>
            <div class="menu_text h-100">Nouvel inscrit</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 5}"
             class="el_link"
             routerLink="./my-events">
            <img alt="" class="icon_menu" src="../../../../assets/images/inscrit_menu.svg">
            <div class="menu_text h-100">Liste des événements</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 5}" class="el_link"
             routerLink="./event-creation">
            <img alt="" class="icon_menu" src="../../../../assets/images/calendar.svg">
            <div class="menu_text h-100">Créer un événement</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('file-manager')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./file-manager/document/5">
            <img alt="" class="icon_menu" src="../../../../assets/images/folder.svg">
            <div class="menu_text h-100">Voir les documents</div>
          </a>
        </mat-expansion-panel>

        <!-- LDLV pannel -->
        <mat-expansion-panel *ngIf="isActif(3)" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              L dans la Ville
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 3, canLogin: true}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">Liste des inscrits</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 3}"
             class="el_link"
             routerLink="./young-creation">
            <mat-icon class="icon_menu">person_add</mat-icon>
            <div class="menu_text h-100">Nouvel inscrit</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 3}"
             class="el_link"
             routerLink="./my-events">
            <img alt="" class="icon_menu" src="../../../../assets/images/inscrit_menu.svg">
            <div class="menu_text h-100">Liste des événements</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 3}" class="el_link"
             routerLink="./event-creation">
            <img alt="" class="icon_menu" src="../../../../assets/images/calendar.svg">
            <div class="menu_text h-100">Créer un événement</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('file-manager')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./file-manager/document/3">
            <img alt="" class="icon_menu" src="../../../../assets/images/folder.svg">
            <div class="menu_text h-100">Voir les documents</div>
          </a>
        </mat-expansion-panel>

        <!-- EDLV pannel -->
        <mat-expansion-panel *ngIf="isActif(4)" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Entrepreneurs dans la Ville
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-young')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 4, canLogin: true}" class="el_link"
             routerLink="./profile-young">
            <mat-icon class="icon_menu">people</mat-icon>
            <div class="menu_text h-100">Liste des inscrits</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('young-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 4}"
             class="el_link"
             routerLink="./young-creation">
            <mat-icon class="icon_menu">person_add</mat-icon>
            <div class="menu_text h-100">Nouvel inscrit</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('my-events')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 4}"
             class="el_link"
             routerLink="./my-events">
            <img alt="" class="icon_menu" src="../../../../assets/images/inscrit_menu.svg">
            <div class="menu_text h-100">Liste des événements</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('event-creation')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             [queryParams]="{ program: 4}" class="el_link"
             routerLink="./event-creation">
            <img alt="" class="icon_menu" src="../../../../assets/images/calendar.svg">
            <div class="menu_text h-100">Créer un événement</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('file-manager')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./file-manager/document/4">
            <img alt="" class="icon_menu" src="../../../../assets/images/folder.svg">
            <div class="menu_text h-100">Voir les documents</div>
          </a>
        </mat-expansion-panel>

        <!-- global admin pannel -->
        <mat-expansion-panel *ngIf="canAccess('admin')" class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Administration
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a (click)="closeSideBar()" *ngIf="canAccess('role-setting')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./role-setting">
            <mat-icon class="icon_menu">perm_data_setting</mat-icon>
            <div class="menu_text h-100">Paramétrage des rôles</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('profile-setting')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./profile-setting">
            <mat-icon class="icon_menu">supervised_user_circle</mat-icon>
            <div class="menu_text h-100">Liste des utilisateurs sdlv</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('place-setting')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./place-setting">
            <mat-icon class="icon_menu">room</mat-icon>
            <div class="menu_text h-100">Gestion des lieux</div>
          </a>

          <a (click)="closeSideBar()" *ngIf="canAccess('session-setting')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
             class="el_link"
             routerLink="./session-setting">
            <mat-icon class="icon_menu">list</mat-icon>
            <div class="menu_text h-100">Gestion des tranches d'âge</div>
          </a>

          <a (click)="sendMail()" *ngIf="canAccess('envoi-mail')"
             [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_menu_expand':'el_link_short el_menu_short'"
             class="el_link">
            <mat-icon *ngIf="!isSending else loading" class="icon_menu" matTooltip="Envoi de mail">email</mat-icon>
            <ng-template #loading>
              <mat-spinner [diameter]="50" class="custom-theme spinner"></mat-spinner>
            </ng-template>
            <div class="menu_text h-100">Envoi de mail</div>
          </a>
        </mat-expansion-panel>

      </mat-accordion>

      <!-- short menu -->

      <!-- Admin short menu -->
      <div class="w-100 d-flex flex-column justify-content-center align-items-center"
           *ngIf="!showSidebarService.showSidebar && canAccess('admin')">

        <a (click)="closeSideBar()" *ngIf="canAccess('referentiel')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./referentiel">
          <mat-icon class="icon_menu" matTooltip="Referentiel" matTooltipPosition="right">settings_applications
          </mat-icon>
          <div class="menu_text h-100">Referentiel</div>
        </a>

        <a (click)="closeSideBar()" *ngIf="canAccess('role-setting')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./role-setting">
          <mat-icon class="icon_menu" matTooltip="Paramétrage des rôles" matTooltipPosition="right">perm_data_setting
          </mat-icon>
          <div class="menu_text h-100">Paramétrage des rôles</div>
        </a>

        <a (click)="closeSideBar()" *ngIf="canAccess('profile-setting')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./profile-setting">
          <mat-icon class="icon_menu" matTooltip="Paramétrage des profils utilisateurs" matTooltipPosition="right">
            supervised_user_circle
          </mat-icon>
          <div class="menu_text h-100">Paramétrage des profils utilisateurs</div>
        </a>

        <a (click)="closeSideBar()" *ngIf="canAccess('user-setting')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./user-setting">
          <mat-icon class="icon_menu" matTooltip="Paramétrage des utilisateurs" matTooltipPosition="right">
            manage_accounts
          </mat-icon>
          <div class="menu_text h-100">Paramétrage des utilisateurs</div>
        </a>

        <a (click)="closeSideBar()" *ngIf="canAccess('place-setting')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./place-setting">
          <mat-icon class="icon_menu" matTooltip="Gestion des lieux" matTooltipPosition="right">room</mat-icon>
          <div class="menu_text h-100">Gestion des lieux</div>
        </a>

        <a (click)="closeSideBar()" *ngIf="canAccess('session-setting')"
           [ngClass]="showSidebarService.showSidebar ? 'el_link_expand el_border_bottom el_menu_expand':'el_link_short el_menu_short'"
           class="el_link"
           routerLink="./session-setting">
          <mat-icon class="icon_menu" matTooltip="Gestion des tranches d'âge" matTooltipPosition="right">list</mat-icon>
          <div class="menu_text h-100">Gestion des tranches d'âge</div>
        </a>
      </div>
    </div>
  </div>
  <div class="version-number" [ngClass]="[this.showSidebarService.showSidebar ? 'expand':'short']">
    {{version}}
  </div>
</div>
