import {Component, OnInit} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Destroyed} from 'src/app/components/shared/directives/destroyed.directive';
import {LoadingService} from 'src/app/services/loading.service';
import {debounceTime} from 'rxjs/operators';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent extends Destroyed implements OnInit {
  loading = false;

  constructor(private readonly loadingService: LoadingService) {
    super();
  }

  ngOnInit() {
    this.loadingService.loadingStatus
      .pipe(debounceTime(50), this.untilDestroyed())
      .subscribe((value) => {
        this.loading = value;
      });
  }
}
