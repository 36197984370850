import {enableProdMode} from '@angular/core';

import {environment} from './environments/environment';
import {ModuleRegistry} from '@ag-grid-community/core';
import {CsvExportModule} from '@ag-grid-community/csv-export';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {AppComponent} from './app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import {appConfig} from './app/app.config';

if (environment.production) {
  enableProdMode();
}

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

registerLocaleData(localeFr, 'fr', localeFrExtra);

bootstrapApplication(AppComponent, appConfig).then();
