import {Component, OnInit} from '@angular/core';
import {HeaderTextService} from '../../../services/header-text.service';
import {DashboardCard} from '../../../model/dashboard/dashboard-card.model';
import {CenterDTO} from '../../../model/dto/center.dto';
import {TreeDataService} from 'src/app/services/tree-data.service';
import {TreeItemFlatNode} from 'src/app/model/event/treeData/tree-item-flat-node.model';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {DashboardType} from '../../../model/dashboard/dashboard.enum';
import {FormatService} from '../../../services/format.service';
import {DashboardCardComponent} from '../dashboard-card/dashboard-card.component';
import {NgFor} from '@angular/common';
import {HeaderDashboardComponent} from 'src/app/components/dashboard/header-dashboard/header-dashboard.component';
import {getMobileToken} from 'src/app/utils/utils.static';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [HeaderDashboardComponent, NgFor, DashboardCardComponent]
})
export class DashboardComponent extends Destroyed implements OnInit {
  readonly colorList = ['#94c01f', '#fab900', '#5b4d6c', '#eb6608', '#e3000b', '#009EE3'];
  readonly neutralColor = '#e0e0e0';
  readonly sportColorList = [
    {label: 'football', value: '#94c01f'},
    {label: 'football Filles', value: '#94c01f'},
    {label: 'basketball', value: '#fab900'},
    {label: 'basketball Filles', value: '#fab900'},
    {label: 'rugby', value: '#5b4d6c'},
    {label: 'tennis', value: '#eb6608'},
    {label: 'Danse', value: '#009EE3'},
    {label: 'Fitness', value: '#009EE3'},
    {label: 'Boxe', value: '#e3000b'},
    {label: 'Boxe Filles', value: '#e3000b'},
    {label: 'Cardio Boxing Filles', value: '#e3000b'},
    {label: 'Cardio Boxing', value: '#e3000b'},
    {label: 'Fille', value: '#009EE3'},
    {label: 'Garçon', value: '#94c01f'}
  ];
  listOfDashboardCard: DashboardCard[];
  centers: CenterDTO[];
  listOfIdPlaces: number[];
  currentDate: Date;
  maxDate: Date;
  minDate: Date;
  private readonly title = 'Bienvenue';

  constructor(
    private readonly headerTextService: HeaderTextService,
    private readonly treeDataService: TreeDataService,
    private readonly formatService: FormatService
  ) {
    super();
    this.listOfDashboardCard = [];
    this.listOfIdPlaces = [];
    this.currentDate = new Date();
    this.centers = [];
  }

  ngOnInit() {
    this.headerTextService.setTitle(this.title);
    this.treeDataService.currentPage = 'Dashboard';
    this.setLimitDate();

    this.initDashboardCards();
  }

  initDashboardCards() {
    let card = new DashboardCard();
    card.dashboardType = DashboardType.REGISTERED_BY_ACTIVITIES;
    card.title = this.formatService.formatDashboardCardTitle(
      DashboardType.REGISTERED_BY_ACTIVITIES
    );
    this.listOfDashboardCard.push(card);
    card = new DashboardCard();
    card.dashboardType = DashboardType.REGISTERED_BY_PROGRAMS;
    card.title = this.formatService.formatDashboardCardTitle(DashboardType.REGISTERED_BY_PROGRAMS);
    this.listOfDashboardCard.push(card);
    card = new DashboardCard();
    card.dashboardType = DashboardType.AVERAGE_PRESENCE;
    card.title = this.formatService.formatDashboardCardTitle(DashboardType.AVERAGE_PRESENCE);
    this.listOfDashboardCard.push(card);
  }

  setLimitDate() {
    const newMaxDate = new Date();
    const newMinDate = new Date(2020, 8, 1);

    this.maxDate = newMaxDate;
    this.minDate = newMinDate;
  }

  displayPercentage(percentage: number) {
    if (percentage < 1) {
      return percentage.toPrecision(2);
    } else {
      if (percentage < 10) {
        return percentage.toPrecision(3);
      } else {
        return percentage.toPrecision(4);
      }
    }
  }

  onDateChange(date: Date) {
    this.currentDate = new Date(date);
  }

  onPlaceChange(data: TreeItemFlatNode[]) {
    this.listOfIdPlaces = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        this.listOfIdPlaces.push(item.id);
      });
    }
  }

  sortDashboardCardByName() {
    return this.listOfDashboardCard.sort((a, b) =>
      a.title > b.title ? 1 : a.title === b.title ? 0 : -1
    );
  }

  protected readonly getMobileToken = getMobileToken;
  protected readonly console = console;
}
