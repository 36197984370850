import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from './components/shared/shared.modules';
import {DialogModule} from './components/shared/dialog/dialog.module';
import {routes} from './app.routes';
import {AgGridModule} from '@ag-grid-community/angular';
import {ReactiveFormsModule} from '@angular/forms';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HeaderTextService} from './services/header-text.service';
import {ActivityInfosService} from './services/activity-infos.service';
import {YoungCurrentlyViewedService} from './services/young-currently-viewed.service';
import {YoungCreationDataService} from './services/young-creation-data.service';
import {FiltersService} from './services/filters.service';
import {ApplicationInsightsService} from './services/application-insights.service';
import {DatePipe} from '@angular/common';
import {DialogService} from './services/dialog.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {datePickerFormat} from './utils/date-picker-format.utils';
import {CustomDateAdapter} from './utils/custom-date-adapter';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {AuthorizationInterceptor} from './utils/interceptor/authorization.interceptor';
import {AuthWebservice} from './services/webservices/auth.webservice';
import {provideAnimations} from '@angular/platform-browser/animations';
import {WindowWebservice} from './services/webservices/window.webservice';
import {UserWebservice} from './services/webservices/user.webservice';
import {LogoutWebservice} from './services/webservices/logout.webservice';
import {PreloadAllModules, provideRouter, withPreloading, withRouterConfig} from '@angular/router';
import {AuthGuardService} from './utils/auth/auth-guard.component';
import {LoadingInterceptor} from 'src/app/utils/interceptor/loading.interceptor';
import {LoadingService} from 'src/app/services/loading.service';
import {TreeDataService} from 'src/app/services/tree-data.service';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from 'src/app/utils/auth/auth.config';

const WEBSERVICES = [
  WindowWebservice,
  AuthWebservice,
  UserWebservice,
  LogoutWebservice,
  AuthGuardService
];

export const appConfig: ApplicationConfig = {
  providers: [
    {provide: 'LOCALE_ID', useValue: 'fr-FR'},
    importProvidersFrom(
      BrowserModule,
      SharedModule,
      DialogModule,
      AgGridModule,
      ReactiveFormsModule,
      MsalModule,
      ToastrModule.forRoot(),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.serviceWorker.enabled,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      })
    ),
    ...WEBSERVICES,
    HeaderTextService,
    ActivityInfosService,
    YoungCurrentlyViewedService,
    YoungCreationDataService,
    FiltersService,
    ApplicationInsightsService,
    DatePipe,
    DialogService,
    LoadingService,
    TreeDataService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_DATE_FORMATS, useValue: datePickerFormat},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
      deps: [ToastrService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withRouterConfig({
        onSameUrlNavigation: 'reload'
      })
    )
  ]
};
