<mat-card appearance="outlined" class="m-3 text-center">
  <h1>APPLICATION</h1>
  <h1>Sport dans la ville</h1>
  <hr>
  <h1>iOS</h1>
  <hr>
  <a
    href="https://apps.apple.com/us/app/sport-dans-la-ville/id1641315006">
    <img alt="download_on_app_store" src="../../../../assets/images/mobile/download_on_app_store.svg"/>
  </a>
  <hr>
  <h1>ANDROID</h1>
  <hr>
  <a href="https://digisportmobiles.blob.core.windows.net/app-mobiles/sportdanslaville.apk">
    <img alt="logo_android" src="../../../../assets/images/mobile/logo_android.jpg"/>
  </a>
</mat-card>
