<app-header-dashboard
  (choixPlaceChange)="onPlaceChange($event)"
  (dateChange)="onDateChange($event)"
  [maxDate]="maxDate"
  [minDate]="minDate">
</app-header-dashboard>
<div class="d-flex flex-row flex-wrap p-3">
  <app-dashboard-card *ngFor="let card of sortDashboardCardByName()"
                      [dashboardCard]="card"
                      [listOfIdPlaces]="listOfIdPlaces"
                      [currentDate]="currentDate"
                      [colorList]="colorList"
                      [sportColorList]="sportColorList"
                      [neutralColor]="neutralColor"
                      class="col-xl-4 col-lg-6 col-12 d-flex flex-column align-items-center"></app-dashboard-card>
</div>

