import {Component, Input, OnInit} from '@angular/core';
import {globalAppParam} from '../../../../environments/environment';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-env-info',
  templateUrl: './env-info.component.html',
  styleUrls: ['./env-info.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass]
})
export class EnvInfoComponent implements OnInit {
  message: string;
  show = false;
  @Input() env;
  @Input() rightOrLeft: 'right' | 'left';

  ngOnInit(): void {
    if (!this.env) {
      this.env = globalAppParam.envName;
    }
    if (!this.rightOrLeft) {
      this.rightOrLeft = this.env === 'dev' ? 'left' : 'right';
    }
    //Prend l'input si saisie sinon la valeur de l'environment
    switch (this.env) {
      case 'integration':
        this.message = 'Intégration';
        this.show = true;
        break;
      case 'recette':
        this.message = 'Recette';
        this.show = true;
        break;
      case 'developpement':
        this.message = 'Développement';
        this.show = true;
        break;
      case 'production':
        this.show = false;
        break;
      default:
        this.show = false;
    }
  }
}
