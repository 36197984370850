import {Routes} from '@angular/router';
import {AppMobileComponent} from './components/app-mobile/app-mobile.component';
import {AppMobileProdComponent} from './components/app-mobile/app-mobile-prod/app-mobile-prod.component';
import {AppMobileRecetteComponent} from './components/app-mobile/app-mobile-recette/app-mobile-recette.component';
import {MsalGuard} from '@azure/msal-angular';
import {authGuardChildren, authGuardMobile} from 'src/app/utils/auth/auth-guard.component';
import {DashboardComponent} from 'src/app/components/dashboard/dashboard/dashboard.component';

const childRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'dashboard'
  },
  {
    path: 'export',
    loadChildren: () =>
      import('./components/export/export-data.module').then((m) => m.ExportDataModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'my-events',
    loadChildren: () =>
      import('./components/events/events-overview/my-events.module').then((m) => m.MyEventsModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'file-manager',
    loadChildren: () =>
      import('./components/file-manager/file-manager.module').then((m) => m.FileManagerModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'young-info/:id',
    loadChildren: () =>
      import('./components/users/young/info/young-info.module').then((m) => m.YoungInfoModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'search-young/:id',
    loadChildren: () =>
      import('./components/users/young/search/search-young.module').then(
        (m) => m.SearchYoungModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'event-creation',
    loadChildren: () =>
      import('./components/events/creation/event-creation.module').then(
        (m) => m.EventCreationModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'list-jeune-by-activite/:id',
    loadChildren: () =>
      import(
        './components/events/listing-young-in-event/list-jeune-by-activite/list-jeune-by-activite.module'
      ).then((m) => m.ListJeuneByActiviteModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'list-jeune-by-one-activite/:id',
    loadChildren: () =>
      import(
        './components/events/listing-young-in-event/list-jeune-by-one-activite/list-jeune-by-one-activite.module'
      ).then((m) => m.ListJeuneByOneActiviteModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'dashboard/home',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuardChildren]
  },
  {
    path: 'event-detail/:id',
    loadChildren: () =>
      import('./components/events/detail/event-detail.module').then((m) => m.EventDetailModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'ensemble-detail/:id',
    loadChildren: () =>
      import('./components/events/ensemble-detail/ensemble-detail.module').then(
        (m) => m.EnsembleDetailModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'occurrence-creation',
    loadChildren: () =>
      import('./components/events/occurrence-creation/occurrence-creation.module').then(
        (m) => m.OccurrenceCreationModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'role-setting',
    loadChildren: () =>
      import('./components/settings/role-settings/role-setting/role-setting.module').then(
        (m) => m.RoleSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'place-setting',
    loadChildren: () =>
      import('./components/settings/place-setting/place-setting.module').then(
        (m) => m.PlaceSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'session-setting',
    loadChildren: () =>
      import('./components/settings/session-setting/session-setting.module').then(
        (m) => m.SessionSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'profile-setting',
    loadChildren: () =>
      import('./components/users/userSDLV/profile/profile-setting/profile-setting.module').then(
        (m) => m.ProfileSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'family-setting',
    loadChildren: () =>
      import('./components/users/family-setting/family-setting.module').then(
        (m) => m.FamilySettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'user-setting',
    loadChildren: () =>
      import('./components/users/user-setting/user-setting.module').then(
        (m) => m.UserSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'profile-detail/:id',
    loadChildren: () =>
      import('./components/users/userSDLV/profile/profile-detail/profile-detail.module').then(
        (m) => m.ProfileDetailModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'profile-creation',
    loadChildren: () =>
      import('./components/users/userSDLV/profile/profile-creation/profile-creation.module').then(
        (m) => m.ProfileCreationModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'profile-young',
    loadChildren: () =>
      import('./components/users/young/listing/profile-young.module').then(
        (m) => m.ProfileYoungModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'young-creation',
    loadChildren: () =>
      import('./components/users/young/creation/young-creation.module').then(
        (m) => m.YoungCreationModule
      ),
    runGuardsAndResolvers: 'always',
    canActivate: [authGuardChildren]
  },
  {
    path: 'young-presence/:id',
    loadChildren: () =>
      import('./components/users/young/presence/young-presence.module').then(
        (m) => m.YoungPresenceModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'young-validation/:id',
    loadChildren: () =>
      import('./components/users/young/validation/young-validation.module').then(
        (m) => m.YoungValidationModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'young-fusion',
    loadChildren: () =>
      import('./components/users/young/fusion/young-fusion.module').then(
        (m) => m.YoungFusionModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'role-users',
    loadChildren: () =>
      import('./components/settings/role-settings/role-user-setting/role-user-setting.module').then(
        (m) => m.RoleUserSettingModule
      ),
    canActivate: [authGuardChildren]
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('./components/reporting/reporting.module').then((m) => m.ReportingModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'structures',
    loadChildren: () =>
      import('./components/entreprise/entreprise.module').then((m) => m.EntrepriseModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'speaker',
    loadChildren: () =>
      import('./components/users/intervenant/speaker.module').then((m) => m.SpeakerModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'depot-document',
    loadChildren: () =>
      import('./components/documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'referentiel',
    loadChildren: () =>
      import('./components/referentiel/referentiel.module').then((m) => m.ReferentielModule),
    canActivate: [authGuardChildren]
  },
  {
    path: 'import',
    loadChildren: () => import('./components/import/import.module').then((m) => m.ImportModule),
    canActivate: [authGuardChildren]
  },
  {
    path: '**',
    loadChildren: () => import('./components/shared/error/error.module').then((m) => m.ErrorModule)
  }
];

export const routes: Routes = [
  {
    path: 'family-setting-mobile',
    loadChildren: () =>
      import('./components/users/family-setting/family-setting.module').then(
        (m) => m.FamilySettingModule
      ),
    canActivate: [authGuardMobile]
  },
  {
    path: 'dashboard-mobile',
    component: DashboardComponent,
    canActivate: [authGuardMobile]
  },
  {
    path: 'app-mobiles',
    component: AppMobileComponent
  },
  {
    path: 'app-mobiles/prod',
    component: AppMobileProdComponent
  },
  {
    path: 'app-mobiles/recette',
    component: AppMobileRecetteComponent
  },
  {
    path: '',
    canActivateChild: [MsalGuard],
    children: childRoutes
  },
  {
    path: '**',
    redirectTo: ''
  }
];





