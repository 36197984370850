import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DashboardCard} from '../../../model/dashboard/dashboard-card.model';
import {ChartDataset, ChartType, Color} from 'chart.js';
import {debounceTime} from 'rxjs/operators';
import {StatisticDTO} from '../../../model/dto/statistic.dto';
import {DashboardType} from '../../../model/dashboard/dashboard.enum';
import {DashboardWebservice} from '../../../services/webservices/dashboard.webservice';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {DashboardItem} from '../../../model/dashboard/dashboard-item.model';
import {DisplayDashboardEnum} from '../../../model/enums/display-dashboard.enum';
import {StatisticsWebservice} from '../../../services/webservices/statistics.webservice';
import {Observable, Subject} from 'rxjs';
import {DashboardItemGraph} from '../../../model/dashboard/dashboard-item-graph.model';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DashboardItemComponent} from './dashboard-item/dashboard-item.component';
import {NgChartsModule} from 'ng2-charts';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {NgClass, NgFor, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../utils/utils.static';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgChartsModule,
    NgClass,
    NgFor,
    DashboardItemComponent,
    MatProgressSpinnerModule
  ]
})
export class DashboardCardComponent extends Destroyed implements OnInit, OnChanges {
  @Input() dashboardCard: DashboardCard;
  @Input() colorList;
  @Input() sportColorList;
  @Input() neutralColor;

  @Input() listOfIdPlaces;
  @Input() currentDate;
  public barChartOptions;
  public barChartData: ChartDataset[];
  public chartType: ChartType = 'doughnut';
  public barChartColors: Color[];
  readonly dashboardType = DashboardType;
  isLoading: boolean = false;
  private readonly filterChangeSubject: Subject<void> = new Subject<void>();
  private readonly filterChange: Observable<void> = this.filterChangeSubject.asObservable();

  constructor(
    private readonly dashboardWebService: DashboardWebservice,
    private readonly statisticsService: StatisticsWebservice
  ) {
    super();
    this.barChartOptions = {
      cutout: '90%',
      tooltips: {
        enabled: false
      },
      responsive: true,
      maintainAspectRatio: false
    };
  }

  ngOnInit() {
    this.getDataFromDashboardType();

    this.filterChange.pipe(this.untilDestroyed(), debounceTime(300)).subscribe(() => {
      this.getDataFromDashboardType();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listOfIdPlaces || changes.currentDate) {
      this.filterChangeSubject.next();
    }
  }

  getDataFromDashboardType() {
    this.isLoading = true;
    switch (this.dashboardCard.dashboardType) {
      case DashboardType.REGISTERED_BY_ACTIVITIES:
        this.dashboardWebService
          .getRegisteredByActivities(this.listOfIdPlaces, this.currentDate)
          .pipe(this.untilDestroyed())
          .subscribe((value) => {
            this.createDashboardElement(value, true, false, false);
          });
        break;
      case DashboardType.REGISTERED_BY_GENDER:
        this.dashboardWebService
          .getRegisteredByGender(this.listOfIdPlaces, this.currentDate)
          .pipe(this.untilDestroyed())
          .subscribe({
            next: (value) => {
              this.createDashboardElement(value, true, false, true);
            }
          });
        break;
      case DashboardType.REGISTERED_BY_PROGRAMS:
        this.statisticsService
          .getProgramRegistrationStatistics(this.listOfIdPlaces, this.currentDate)
          .pipe(this.untilDestroyed())
          .subscribe({
            next: (value) => {
              this.createDashboardElement(value, true, true, false);
            }
          });
        break;
      case DashboardType.AVERAGE_PRESENCE:
        this.statisticsService
          .getPresenceStatistics(this.listOfIdPlaces, this.currentDate)
          .pipe(this.untilDestroyed())
          .subscribe({
            next: (value: StatisticDTO) => {
              this.createDashboardElement(value, false, false, false);
            }
          });
        break;
      case DashboardType.TRANCHE_SPORT_PRESENCE:
        this.statisticsService
          .getPresenceTrancheStatistics(this.listOfIdPlaces, this.currentDate)
          .pipe(this.untilDestroyed())
          .subscribe({
            next: (value: StatisticDTO) => {
              this.createDashboardElement(value, false, false, false);
            }
          });
        break;
    }
  }

  setup() {
    let data: DashboardItemGraph[] = [];
    if (this.dashboardCard.mainItem) {
      data = this.dashboardCard.mainItem.values ? this.dashboardCard.mainItem.values : [];
      this.setupBarChartData(data);
    }
    this.isLoading = false;
  }

  setupBarChartData(data: DashboardItemGraph[]) {
    this.barChartData = [
      {
        data: data.map((item) => {
          return item.value;
        }),
        backgroundColor: data.map((item) => {
          return item.color;
        }),
        borderWidth: 0
      }
    ];
  }

  changeCard(dashboardType: DashboardType) {
    this.dashboardCard.dashboardType = dashboardType;
    this.getDataFromDashboardType();
  }

  createDashboardElement(
    element: StatisticDTO,
    isRegistration: boolean,
    isBarChart: boolean,
    isPercentage: boolean
  ) {
    this.dashboardCard.title = element.title;
    let sum = 0;

    if (!isBarChart) {
      this.handleIsBarChart(element, sum, isPercentage, isRegistration);
    }

    this.dashboardCard.listOfItem = new Array<DashboardItem>();
    element.listOfSimpleStatDashboardUniqueDTO.forEach((value, key) => {
      sum = value.valeur;
      if (!isBarChart) {
        this.dashboardCard.listOfItem.push(
          new DashboardItem({
            label: value.title,
            displayValue: value.valeur.toString(),
            isPercentage,
            optionalDisplayValue: isNotNullOrUndefined(value.optionalValue)
              ? value.optionalValue.toString() + '%'
              : null,
            values: [
              {
                color: this.getColorRegistration(isRegistration, value, key),
                value: value.valeur
              },
              {
                color: this.neutralColor,
                value: this.getValueColor(sum, element)
              }
            ],
            progress: 0,
            displayType: isRegistration ? DisplayDashboardEnum.COLOR : DisplayDashboardEnum.GRAPH
          })
        );
      } else {
        const percentage = (value.valeur / element.simpleStatDashboardUniqueDTO.valeur) * 100;
        this.dashboardCard.listOfItem.push(
          new DashboardItem({
            label: value.title,
            displayValue: value.valeur.toString(),
            isPercentage,
            optionalDisplayValue: null,
            values: [
              {
                color: '#009ee3',
                value: value.valeur
              },
              {
                color: this.neutralColor,
                value: this.getValueColor(sum, element)
              }
            ],
            progress: 0,
            displayType: isRegistration ? DisplayDashboardEnum.COLOR : DisplayDashboardEnum.GRAPH,
            leftLabel: this.displayPercentage(percentage) + ' % des inscrits'
          })
        );
      }
    });
    this.setup();
  }

  getValueColor(sum, element) {
    return sum === 0 ? 100 : element.simpleStatDashboardUniqueDTO.valeur - sum;
  }

  getColorRegistration(isRegistration, value, key) {
    return isRegistration
      ? this.getSportColor(value.title)
      : this.getColor(key % this.colorList.length);
  }

  handleIsBarChart(element, sum, isPercentage, isRegistration) {
    this.handlePercent(element, isPercentage);
    element.listOfSimpleStatDashboardUniqueDTO.forEach((value, key) => {
      sum = sum + value.valeur;
      this.dashboardCard.mainItem.values.push({
        value: value.valeur,
        color: isRegistration
          ? this.getSportColor(value.title)
          : this.getColor(key % this.colorList.length)
      });
    });
    if (!isPercentage) {
      this.dashboardCard.mainItem.values.push({
        value: this.getValueColor(sum, element),
        color: this.neutralColor
      });
    }
  }

  handlePercent(element: StatisticDTO, isPercentage: boolean) {
    if (isPercentage) {
      this.dashboardCard.mainItem = new DashboardItem({
        label: element.simpleStatDashboardUniqueDTO.title,
        displayValue: element.simpleStatDashboardUniqueDTO.valeur.toString() + '%',
        isPercentage,
        optionalDisplayValue: null,
        values: []
      });
    } else {
      this.dashboardCard.mainItem = new DashboardItem({
        label: element.simpleStatDashboardUniqueDTO.title,
        displayValue: element.simpleStatDashboardUniqueDTO.valeur.toString(),
        isPercentage,
        optionalDisplayValue: isNotNullOrUndefined(
          element.simpleStatDashboardUniqueDTO.optionalValue
        )
          ? element.simpleStatDashboardUniqueDTO.optionalValue.toString() + '%'
          : null,
        values: []
      });
    }
  }

  displayPercentage(percentage: number) {
    if (percentage < 1) {
      return percentage.toPrecision(2);
    } else {
      if (percentage < 10) {
        return percentage.toPrecision(3);
      } else {
        return percentage.toPrecision(4);
      }
    }
  }

  getSportColor(sportCategorie: string) {
    const color = this.sportColorList.find(
      (e) => e.label.toLocaleLowerCase().trim() === sportCategorie.toLocaleLowerCase().trim()
    );
    if (color) {
      return color.value;
    } else {
      return sportCategorie[0];
    }
  }

  getColor(i: number) {
    return this.colorList[i];
  }
}
