<mat-card appearance="outlined" class="m-3 text-center">
  <h1>APPLICATION DE RECETTE</h1>
  <h1>Sport dans la ville</h1>
  <hr>
  <h1>ANDROID</h1>
  <hr>
  <a href="https://digisportmobiles.blob.core.windows.net/app-mobiles-recette/sportdanslaville.apk">
    <img alt="logo_android" src="../../../../assets/images/mobile/logo_android.jpg"/><br/>
  </a>
</mat-card>
