<mat-card appearance="outlined" *ngIf="dashboardCard" class="d-flex flex-column align-items-center p-0">
  <div class="d-flex justify-content-between dashboard-card-header align-items-center px-4 py-2">
    <h3>{{dashboardCard.title}}</h3>
    <button *ngIf="dashboardCard.title.includes('activité') || dashboardCard.title.includes('genre')"
            [matMenuTriggerFor]="menuInscriptions"
            aria-label="Paramètres" mat-icon-button>
      <mat-icon>more_horiz</mat-icon>
    </button>
    <button *ngIf="dashboardCard.title.toLocaleLowerCase().includes('présents')" [matMenuTriggerFor]="menuMoyenne"
            aria-label="Paramètres" mat-icon-button>
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="!isLoading; else loading">
    <div *ngIf="dashboardCard.mainItem" class="mx-3 my-4 main-chart">
      <canvas [type]="chartType"
              [datasets]="barChartData"
              [options]="barChartOptions"
              height="250"
              width="500"
              baseChart
      ></canvas>
      <div class="main-text d-flex flex-column justify-content-center align-items-center">

        <h4 class="main-value">{{this.dashboardCard.mainItem.displayValue}}</h4>
        <p class="main-label">{{dashboardCard.mainItem.label}}
          <span *ngIf="dashboardCard.mainItem.progress"
                [ngClass]="{'bad-progress': (dashboardCard.mainItem.progress && dashboardCard.mainItem.progress < 0)}"
                class="progress">
          <span *ngIf="dashboardCard.mainItem.progress > 0">+</span>{{dashboardCard.mainItem.progress}}
        </span>
        </p>
      </div>
    </div>
    <div class="d-flex flex-row flex-wrap tableau-de-bord-items dashboard-card-items">
      <app-dashboard-item *ngFor="let item of dashboardCard.listOfItem" [item]="item"
                          [ngClass]="{'col-6': !item.leftLabel, 'col-12': item.leftLabel}"></app-dashboard-item>
    </div>
  </ng-container>

  <mat-menu #menuInscriptions="matMenu">
    <button (click)="changeCard(dashboardType.REGISTERED_BY_ACTIVITIES)" mat-menu-item>Inscriptions par activité
    </button>
    <button (click)="changeCard(dashboardType.REGISTERED_BY_GENDER)" mat-menu-item>Inscrits par genre</button>
  </mat-menu>

  <mat-menu #menuMoyenne="matMenu">
    <button (click)="changeCard(dashboardType.AVERAGE_PRESENCE)" mat-menu-item>Moyenne des présents</button>
    <button (click)="changeCard(dashboardType.TRANCHE_SPORT_PRESENCE)" mat-menu-item>Présents par tranche d'âge/sport
    </button>
  </mat-menu>

</mat-card>


<ng-template #loading>
  <label>Chargement en cours...</label>
</ng-template>
