import {Component, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {Observable, of, ReplaySubject} from 'rxjs';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {IntervenantTypeEnum} from 'src/app/model/enums/intervenant-type.enum';
import {User} from 'src/app/model/user/user.model';
import {FormatService} from 'src/app/services/format.service';
import {UserWebservice} from 'src/app/services/webservices/user.webservice';
import {FilterUser} from 'src/app/model/filter/filter-relation-binome/filter-user.model';
import {debounceTime} from 'rxjs/operators';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-dialog-select-intervenant',
  templateUrl: './dialog-select-intervenant.component.html',
  styleUrls: ['./dialog-select-intervenant.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogTitle,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    NgFor,
    MatListModule,
    MatIconModule,
    AsyncPipe
  ]
})
export class DialogSelectIntervenantComponent implements OnInit {
  intervenantTypeEnum: IntervenantTypeEnum;
  isLoading = true;

  filteredIntervenantObservable: Observable<Paged<User>> = of(new Paged<User>());
  filteredIntervenant: FilterUser;

  selectedIntervenant: User[];

  searchYear: number;
  intervenant: User[];

  IdIntervenant: number;

  public listUsers: User[];
  public userMultiFilterCtrl = new UntypedFormControl();
  public filteredUserObservable: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  constructor(
    private readonly dialogRef: MatDialogRef<DialogSelectIntervenantComponent>,
    private readonly formatService: FormatService,
    private readonly userWS: UserWebservice
  ) {}

  ngOnInit() {
    this.selectedIntervenant = [];
    const valueToSend = new FilterUser();
    valueToSend.name = '';
    this.userWS
      .getAllIntervenant(this.intervenantTypeEnum, valueToSend)
      .pipe(debounceTime(1000))
      .subscribe((res) => {
        this.filteredIntervenantObservable = of(res);
      });
    this.userMultiFilterCtrl.valueChanges.subscribe((res) => {
      this._filterIntervenantList(res);
    });
  }

  onDismiss(): void {
    this.dialogRef.close({dismiss: true, data: null});
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.intervenant);
  }

  displayFn(item): string {
    return item && item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : '';
  }

  public onChangeIntervenant(event) {}

  getIntervenant(event) {
    event.value.map((res) => {
      const index = this.selectedIntervenant.indexOf(res);
      if (index === -1) {
        this.selectedIntervenant.push(res);
      }
    });
  }

  deleteIntervenant(intervenant) {
    const index = this.selectedIntervenant.indexOf(intervenant);
    if (index > -1) {
      this.selectedIntervenant.splice(index, 1);
    }
  }

  private _filterIntervenantList(value) {
    if (typeof value === 'string') {
      let filterValue = this.formatService.clearAccent(value.toLowerCase().trim());
      const valueToSend = new FilterUser();
      filterValue = filterValue.replace(/\s/g, '');
      valueToSend.name = filterValue;
      if (value.length > 1) {
        this.filteredIntervenantObservable = this.userWS
          .getAllIntervenant(this.intervenantTypeEnum, valueToSend)
          .pipe(debounceTime(1000));
      }
    }
  }
}
