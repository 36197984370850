import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {AuthWebservice} from 'src/app/services/webservices/auth.webservice';
import {UserWebservice} from 'src/app/services/webservices/user.webservice';
import {ApplicationInsightsService} from 'src/app/services/application-insights.service';
import {Destroyed} from 'src/app/components/shared/directives/destroyed.directive';
import {CurrentUserService} from 'src/app/services/current-user.service';
import {RefreshUserDto} from 'src/app/model/dto/refresh-user.dto';
import {getMobileToken, isNotNullOrUndefined} from 'src/app/utils/utils.static';
import {take} from 'rxjs';
import {User} from 'src/app/model/user/user.model';

export const authGuardMobile: CanActivateFn = (route, _state) => {
  const authGuardService = inject(AuthGuardService);
  return authGuardService.checkAuthMobile(route);
};


export const authGuardChildren: CanActivateChildFn = async (route, _state) => {
  const authGuardService = inject(AuthGuardService);

  return await authGuardService.checkAuth(route);
};

@Injectable()
export class AuthGuardService extends Destroyed {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthWebservice,
    private readonly userService: UserWebservice,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly currentUserService: CurrentUserService
  ) {
    super();
  }

  checkAuth(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.currentUserService.currentUser) {
        this.userService
          .refreshCurrentUser()
          .pipe(this.untilDestroyed())
          .subscribe({
            next: async (data: RefreshUserDto) => {
              this.currentUserService.currentUser = data.user;
              this.currentUserService.roleUser = data.listOfRoleUser;
              this.currentUserService.currentUserModule = data.listOfModule;
              this.applicationInsightsService.setUserId(
                this.currentUserService.currentUser.id.toString()
              );
              this.authService.isAuth = true;

              resolve(await this.redirectValidationYoung(route));
            },
            error: () => {
              resolve(false);
            }
          });
      } else {
        resolve(await this.redirectValidationYoung(route));
      }
    });
  }

  checkAuthMobile(route: ActivatedRouteSnapshot): Promise<boolean> {
    const token = getMobileToken();

    // Check si a un token dans le local storage
    //    Si oui vérifie que le token est toujours valide
    //      Si non le rafraichi
    //      Dans tous les cas rafraichi l'objet user de userService
    //      Valide le rôle de l'user
    //
    // Si non déco

    if (isNotNullOrUndefined(token)) {
      // Pas de token --> doit se reco
      return new Promise<boolean>(async (resolve) => {

        this.authService.isAuth = true;

        if (!this.currentUserService.currentUser) {
          // Pas d'utilisateur stocké en ce moment dans le userService
          this.userService
            .refreshCurrentUser()
            .pipe(take(1))
            .subscribe({
              next: async (data: RefreshUserDto) => {
                this.currentUserService.currentUser = data.user;
                this.currentUserService.currentUserModule = data.listOfModule;
                this.applicationInsightsService.setUserId(
                  this.currentUserService.currentUser.id.toString()
                );
                resolve(this.redirectMobile(route));
              },
              error: () => {
                this.currentUserService.currentUser = new User();
                this.currentUserService.currentUser.id = -1;
                resolve(false);
              }
            });
        } else {
          resolve(this.redirectMobile(route));
        }
      })
        .catch(() => {
          return false;
        })
        .then((value) => {
          return value;
        });
    } else {
      // No token in local storage
      return Promise.resolve(false);
    }
  }

  checkHasRightToAccess() {
    this.authService.isAuthBDD = true;
    return true;
  }

  canAccessYoungValidation(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      const isValid = this.userService.isValid(+route.paramMap.get('id')).subscribe({
        next: (value: boolean) => {
          if (value) {
            resolve(false);
          } else {
            resolve(true);
          }
        },
        complete: () => {
          isValid.unsubscribe();
        }
      });
    });
  }

  async redirectValidationYoung(route: ActivatedRouteSnapshot) {
    if (this.currentUserService.canAccess(route.routeConfig.path)) {
      // check young validation (redirect si jeune deja valid)
      if (route.routeConfig.path === 'young-validation/:id') {
        if (await this.canAccessYoungValidation(route)) {
          return this.checkHasRightToAccess();
        } else {
          return await this.router.navigate(['profile-young']);
        }
      } else {
        return this.checkHasRightToAccess();
      }
    } else {
      return await this.router.navigate(['']);
    }
  }

  redirectMobile(route: ActivatedRouteSnapshot) {
    return this.currentUserService.canAccess(route.routeConfig.path);
  }
}
