<mat-card appearance="outlined" class="m-3">
  <button mat-button routerLink="/login">Retour à la page de login</button>
  <div>
    <h1>APPLICATIONS</h1>
    <h1>Sport dans la ville</h1>
  </div>
  <div style="margin-bottom: 20px; justify-content: space-evenly">
    <button mat-button routerLink="/app-mobiles/prod">Production</button>
    <button mat-button routerLink="/app-mobiles/recette">Recette</button>
  </div>

</mat-card>
