import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ToastService} from '../../services/toast.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastService: ToastService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status !== HttpStatusCode.Unauthorized) {
          this.toastService.error(
            error?.error?.message ? error?.error?.message : '',
            'Une erreur est survenue'
          );
        }
        return throwError(() => alert);
      })
    );
  }
}
