import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from 'src/app/services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests = 0;

  /**
   * URLs ou l'on désactive l'ecran de chargement
   */
  skipUrls = ['dashboard'];

  constructor(private readonly loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let displayLoadingScreen = true;

    /**
     * Urls à ignorer
     */
    for (const url of this.skipUrls) {
      if (new RegExp(url).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    if (displayLoadingScreen) {
      this.loadingService.startLoading();
      /**
       * Faire evoluer le nombre de requetes en cours et update sur oui ou non utiliser un écran de chargement.
       */
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests <= 0) {
            this.activeRequests = 0;
            this.loadingService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
