import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[clickOutsideMenu]',
  standalone: true
})
export class ClickOutsideDirective {
  @Output() clickOutsideMenu: EventEmitter<boolean | null> = new EventEmitter();

  constructor(private readonly _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    const menuImage = document.getElementById('menu_icon');
    if (!clickedInside && targetElement !== menuImage) {
      this.clickOutsideMenu.emit(null);
    }
  }
}
