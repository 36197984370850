import {Component} from '@angular/core';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-refresh-app',
  templateUrl: './dialog-refresh-app.component.html',
  styleUrls: ['./dialog-refresh-app.component.scss'],
  standalone: true,
  imports: [MatDialogTitle]
})
export class DialogRefreshAppComponent {
  constructor(private readonly dialogRef: MatDialogRef<DialogRefreshAppComponent>) {}

  dismiss() {
    this.dialogRef.close();
  }
}
