import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogDefaultComponent} from './dialog-default/dialog-default.component';
import {DialogSelectYoungCreationDuplicateComponent} from './dialog-select-young-creation-duplicate/dialog-select-young-creation-duplicate.component';
import {DialogSelectYoungCreationFamilyComponent} from './dialog-select-young-creation-family/dialog-select-young-creation-family.component';
import {DialogConfirmComponent} from './dialog-confirm/dialog-confirm.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {SharedModule} from '../shared.modules';
import {CustomAgGridModule} from '../ag-grid/custom-ag-grid.module';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogAddPlaceComponent} from './dialog-add-place/dialog-add-place.component';

import {DialogAddSessionComponent} from './dialog-add-session/dialog-add-session.component';
import {DialogShowDocsComponent} from './dialog-show-docs/dialog-show-docs.component';
import {DialogEditNameComponent} from './dialog-edit-name/dialog-edit-name.component';
import {DialogConfirmDeleteUserComponent} from './dialog-confirm-delete-user/dialog-confirm-delete-user.component';
import {DialogConfirmDeleteUserSdlvComponent} from './dialog-confirm-delete-user-sdlv/dialog-confirm-delete-user-sdlv.component';
import {DialogSelectRespLegalComponent} from './dialog-select-resp-legal/dialog-select-resp-legal.component';
import {DialogAddEnsembleComponent} from './dialog-add-ensemble/dialog-add-ensemble.component';
import {SetManagementComponent} from './dialog-add-ensemble/set-management/set-management.component';
import {DialogSelectIntervenantComponent} from './dialog-select-intervenant/dialog-select-intervenant.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DialogSelectParcoursComponent} from './dialog-select-parcours/dialog-select-parcours.component';
import {DialogSelectPillarComponent} from './dialog-select-pillar/dialog-select-pillar.component';
import {SetAttributionComponent} from './dialog-add-ensemble/set-attribution/set-attribution.component';
import {DialogSelectYoungEventComponent} from './dialog-select-young-event/dialog-select-young-event.component';
import {DialogAddWorkExperienceComponent} from './dialog-add-work-experience/dialog-add-work-experience.component';
import {DialogAddInterviewComponent} from './dialog-add-interview/dialog-add-interview.component';
import {DialogAddMonitoringComponent} from './dialog-add-monitoring/dialog-add-monitoring.component';
import {DialogAttestationComponent} from './dialog-attestation/dialog-attestation.component';
import {DialogSelectYoungInfoDuplicateComponent} from './dialog-select-young-info-duplicate/dialog-select-young-info-duplicate.component';
import {DialogAddStructureComponent} from './dialog-add-structure/dialog-add-structure.component';
import {DateAdapter} from '@angular/material/core';
import {CustomDateAdapter} from '../../../utils/custom-date-adapter';
import {DialogAddDocAnnexeComponent} from './dialog-add-doc-annexe/dialog-add-doc-annexe.component';
import {FileManagerModule} from '../../file-manager/file-manager.module';
import {DialogAddDocComponent} from './dialog-add-doc/dialog-add-doc.component';
import {DialogQuestionnaireComponent} from './dialog-questionnaire/dialog-questionnaire.component';
import {DialogEditOccurrenceComponent} from './dialog-edit-occurrence/dialog-edit-occurrence.component';
import {DialogAddParamComponent} from './dialog-add-param/dialog-add-param.component';
import {DialogInscriptionErrorsComponent} from './dialog-inscription-errors/dialog-inscription-errors.component';
import {DialogConfirmSuppressionRelationComponent} from './dialog-confirm-suppression-relation/dialog-confirm-suppression-relation.component';
import {DialogRefreshAppComponent} from './dialog-refresh-app/dialog-refresh-app.component';
import {DialogAddVisitComponent} from './dialog-add-visit/dialog-add-visit.component';
import {CreateVisitFormComponent} from '../../users/family-setting/visit-family-form/create-visit-form/create-visit-form.component';
import {DialogPrevisualizeVisitPdfComponent} from './dialog-previsualize-visit-pdf/dialog-previsualize-visit-pdf.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {DialogCheckRgpdContactComponent} from './dialog-check-rgpd-contact/dialog-check-rgpd-contact.component';

const DIALOG_COMPONENTS = [
  DialogDefaultComponent,
  DialogSelectYoungCreationDuplicateComponent,
  DialogSelectYoungCreationFamilyComponent,
  DialogConfirmComponent,
  DialogAddPlaceComponent,
  DialogAddSessionComponent,
  DialogShowDocsComponent,
  DialogEditNameComponent,
  DialogConfirmDeleteUserComponent,
  DialogConfirmDeleteUserSdlvComponent,
  DialogSelectRespLegalComponent,
  DialogAddEnsembleComponent,
  SetManagementComponent,
  SetAttributionComponent,
  DialogSelectIntervenantComponent,
  DialogSelectParcoursComponent,
  DialogSelectPillarComponent,
  DialogSelectYoungEventComponent,
  DialogAddInterviewComponent,
  DialogAddWorkExperienceComponent,
  DialogAddMonitoringComponent,
  DialogAttestationComponent,
  DialogSelectYoungInfoDuplicateComponent,
  DialogAddStructureComponent,
  DialogAddDocAnnexeComponent,
  DialogAddDocComponent,
  DialogQuestionnaireComponent,
  DialogAddParamComponent,
  DialogInscriptionErrorsComponent,
  DialogEditOccurrenceComponent,
  DialogConfirmSuppressionRelationComponent,
  DialogRefreshAppComponent,
  DialogAddVisitComponent,
  DialogRefreshAppComponent,
  CreateVisitFormComponent,
  DialogPrevisualizeVisitPdfComponent
];

@NgModule({
    imports: [
    CommonModule,
    AgGridModule,
    SharedModule,
    CustomAgGridModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    FileManagerModule,
    NgxExtendedPdfViewerModule,
    ...DIALOG_COMPONENTS, DialogCheckRgpdContactComponent
],
    exports: [
        ...DIALOG_COMPONENTS
    ],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter }
    ]
})
export class DialogModule {
}
