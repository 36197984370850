<div class="all-container">
  <app-env-info></app-env-info>
  <app-loading></app-loading>
  <app-sidebar *ngIf="isLoggedIn()"></app-sidebar>
  <div class="content-container" cdkScrollable>
    <app-toolbar></app-toolbar>
    <div class="route-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
