import {Injectable} from '@angular/core';
import {ActivityInfosService} from './activity-infos.service';
import {YoungCurrentlyViewedService} from './young-currently-viewed.service';
import {FiltersService} from './filters.service';
import {TreeDataService} from './tree-data.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private readonly activity: ActivityInfosService,
    private readonly young: YoungCurrentlyViewedService,
    private readonly filter: FiltersService,
    private readonly treeDataService: TreeDataService
  ) {}

  // Reset service stockage
  reset() {
    this.treeDataService.reset();
    this.activity.reset();
    this.filter.reset();
    this.young.reset();
  }
}
