<div class="dialog-default d-flex flex-column">
  <h1 *ngIf="intervenantTypeEnum === 0" class="m-0" mat-dialog-title>Ajouter un intervenant</h1>
  <h1 *ngIf="intervenantTypeEnum === 1" class="m-0" mat-dialog-title>Ajouter un accompagnateur</h1>
  <div class="d-flex flex-row align-items-center my-2 mx-2">
    <mat-form-field appearance="outline" disabled="isLoading">
      <mat-label *ngIf="intervenantTypeEnum === 0">Intervenant</mat-label>
      <mat-label *ngIf="intervenantTypeEnum === 1">Accompagnateur</mat-label>
      <mat-select (selectionChange)="getIntervenant($event)" [(ngModel)]="intervenant" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="userMultiFilterCtrl"
                                 noEntriesFoundLabel="Aucun résultat correspondant"
                                 placeholderLabel="Trouvez un intervenant"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let item of (filteredIntervenantObservable | async)?.items"
                    [value]="item">{{ item.firstName + " " + item.lastName }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-flex flex-row justify-content-end">
    <button (click)="close()" class="btn btn-warning my-2 mx-2" type="button">Annuler</button>
    <button (click)="add()" class="btn btn-primary my-2 mx-2" type="button">Ajouter</button>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <mat-list #intervenants>
      <mat-list-item *ngFor="let intervenant of selectedIntervenant">
        {{ intervenant.firstName + " " + intervenant.lastName }}
        <span><mat-icon (click)="deleteIntervenant(intervenant)">delete</mat-icon></span>
      </mat-list-item>
    </mat-list>
  </div>


</div>

