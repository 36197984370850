import {Component} from '@angular/core';
import {AuthWebservice} from './services/webservices/auth.webservice';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {Destroyed} from './components/shared/directives/destroyed.directive';
import {interval} from 'rxjs';
import {DialogRefreshAppComponent} from './components/shared/dialog/dialog-refresh-app/dialog-refresh-app.component';
import {MatDialog} from '@angular/material/dialog';
import {RouterOutlet} from '@angular/router';
import {ToolbarComponent} from './components/shared/toolbar/toolbar.component';
import {SidebarComponent} from './components/shared/sidebar/sidebar.component';
import {NgIf} from '@angular/common';
import {EnvInfoComponent} from './components/shared/env-info/env-info.component';
import {LoadingComponent} from 'src/app/components/shared/loading/loading.component';
import {PrimeNGConfig} from 'primeng/api';
import * as primeConfig from '../assets/i18n/primeng-fr.json';
import {ScrollingModule} from "@angular/cdk/scrolling";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    ScrollingModule,
    EnvInfoComponent,
    NgIf,
    SidebarComponent,
    ToolbarComponent,
    RouterOutlet,
    LoadingComponent
  ]
})
export class AppComponent extends Destroyed {
  private popupAlreadyOpened = false;

  constructor(
    private readonly authService: AuthWebservice,
    private readonly swUpdate: SwUpdate,
    private readonly dialog: MatDialog,
    private readonly primeNgConfig: PrimeNGConfig
  ) {
    super();
    this.initializeSw();
    this.primeNgConfig.setTranslation(primeConfig);
  }

  isLoggedIn() {
    return this.authService.isAuthBDD;
  }

  initializeSw() {
    try {
      const every5Mins$ = interval(5 * 60 * 1000);
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
        every5Mins$.pipe(this.untilDestroyed()).subscribe(() => {
          if (!this.popupAlreadyOpened) {
            this.swUpdate.checkForUpdate();
          }
        });

        this.swUpdate.versionUpdates
          .pipe(this.untilDestroyed())
          .subscribe((value: VersionEvent) => {
            if (value.type === 'VERSION_READY' && !this.popupAlreadyOpened) {
              this.popupAlreadyOpened = true;
              const dialogRef = this.dialog.open(DialogRefreshAppComponent);
              dialogRef
                .afterClosed()
                .pipe(this.untilDestroyed())
                .subscribe(() => {
                  this.popupAlreadyOpened = false;
                  this.swUpdate.activateUpdate().then(() => {
                    //Perform your action here
                    window.location.reload();
                  });
                });
            }
          });
      }
    } catch (e) {}
  }
}
