import {Component} from '@angular/core';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-app-mobile-recette',
  templateUrl: './app-mobile-recette.component.html',
  styleUrls: ['./app-mobile-recette.component.scss'],
  standalone: true,
  imports: [MatCardModule]
})
export class AppMobileRecetteComponent {}
