import {Injectable} from '@angular/core';
import {User} from '../model/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class YoungCurrentlyViewedService {
  public youngCurrentlyViewed: User;

  reset() {
    this.youngCurrentlyViewed = undefined;
  }
}
