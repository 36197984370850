import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatisticDTO} from '../../model/dto/statistic.dto';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class DashboardWebservice {
  private readonly root = `${environment.envApiUrl}/dashboard`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient,
              private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  getRegisteredByActivities(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    const token = getMobileToken();

    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.post<StatisticDTO>(
        `${this.root}/registered-by-activities?month=${
          date.getMonth() + 1
        }&year=${date.getFullYear()}`,
        listOfIdPlace,
        options
      );
    } else {
      return this.http.post<StatisticDTO>(
        `${this.root}/registered-by-activities?month=${
          date.getMonth() + 1
        }&year=${date.getFullYear()}`,
        listOfIdPlace
      );
    }
  }

  getRegisteredByGender(listOfIdPlace: number[], date?: Date): Observable<StatisticDTO> {
    const token = getMobileToken();

    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.post<StatisticDTO>(
        `${this.root}/registered-by-gender?month=${date.getMonth() + 1}&year=${date.getFullYear()}`,
        listOfIdPlace, options
      );
    } else {
      return this.http.post<StatisticDTO>(
        `${this.root}/registered-by-gender?month=${date.getMonth() + 1}&year=${date.getFullYear()}`,
        listOfIdPlace
      );
    }
  }
}
