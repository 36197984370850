import {Component, Input, OnInit} from '@angular/core';
import {DashboardItem} from '../../../../model/dashboard/dashboard-item.model';
import {ChartDataset, ChartType, Color} from 'chart.js';
import {DisplayDashboardEnum} from '../../../../model/enums/display-dashboard.enum';
import {
  DashboardItemGraph,
  DashboardItemGraphInline
} from '../../../../model/dashboard/dashboard-item-graph.model';
import {NgChartsModule} from 'ng2-charts';
import {NgClass, NgFor, NgIf, NgStyle} from '@angular/common';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgChartsModule, NgStyle, NgClass, NgFor]
})
export class DashboardItemComponent implements OnInit {
  @Input() item: DashboardItem;

  type = 0;
  logoPath: string;
  backgroudColor: string;
  barChartData: ChartDataset[];
  barChartOptions;

  chartType: ChartType = 'doughnut';
  barChartColors: Color[];
  inlineGraphDisplay: DashboardItemGraphInline[];

  ngOnInit() {
    if (this.item) {
      if (this.item.displayType >= 0) {
        this.setupType(this.item.displayType.valueOf());
      } else {
        this.setupType(DisplayDashboardEnum.COLOR);
      }

      const logoPathMap = [
        {label: 'football', path: '/assets/images/Foot.svg'},
        {label: 'rugby', path: '/assets/images/Rugby.svg'},
        {label: 'tennis', path: '/assets/images/Tennis.svg'},
        {label: 'basket', path: '/assets/images/Basket.svg'},
        {label: 'danse', path: '/assets/images/Dancer.svg'},
        {label: 'Box', path: '/assets/images/Boxe.svg'},
        {label: 'Femme', path: '/assets/images/Fille.svg'},
        {label: 'Homme', path: '/assets/images/Garcon.svg'},
        {label: 'Fitness', path: '/assets/images/Fitness.svg'},
        {label: 'Match', path: '/assets/images/Match.svg'},
        {label: 'atelier', path: '/assets/images/Nutrition.svg'},
        {label: 'Tournoi', path: '/assets/images/Tournament.svg'},
        {label: 'Sorties', path: '/assets/images/Cultural.svg'},
        {label: 'fédérateur', path: '/assets/images/Federator.svg'}
      ];

      for (const elem of logoPathMap) {
        if (
          this.item.label.toLocaleLowerCase().trim().includes(elem.label.toLocaleLowerCase().trim())
        ) {
          this.logoPath = elem.path;
          break;
        }
      }

      if (
        this.item.values &&
        this.item.values[0] &&
        this.item.values[0].color &&
        this.item.values[0].color.length > 1
      ) {
        this.backgroudColor = this.item.values[0].color;
      } else {
        this.backgroudColor = '#817E7E';
      }
    }

    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: 'top'
        },
        tooltip: {
          enabled: false
        }
      },
      cutout: '85%'
    };
  }

  setupType(value: number) {
    if (this.item.leftLabel) {
      this.type = 2; // graphe en ligne
      this.setupLineGraphe();
    } else {
      switch (value) {
        case DisplayDashboardEnum.GRAPH: {
          this.type = 0; // graphe rond
          this.setupGraphe();
          break;
        }
        case DisplayDashboardEnum.COLOR: {
          this.type = 1; // couleur
          this.setupColor();
          break;
        }

        default: {
          this.setupColor();
          break;
        }
      }
    }
  }

  setupLineGraphe() {
    let sum = 0;
    this.inlineGraphDisplay = [];
    this.item.values.forEach((value) => {
      sum += value.value;
    });

    this.inlineGraphDisplay = this.item.values.map((value) => {
      return {
        value: (value.value / sum) * 100 + '%',
        color: value.color
      };
    });
  }

  setupGraphe() {
    this.setupBarChartData(this.item.values ? this.item.values : []);
  }

  setupColor() {
    this.setupBarChartData([]);
  }

  setupBarChartData(data: DashboardItemGraph[]) {
    this.barChartData = [
      {
        data: data.map((item) => {
          return item.value ? item.value : 0;
        }),
        backgroundColor: data.map((item) => {
          return item.color ? item.color : '#817E7E';
        }),
        borderWidth: 0
      }
    ];
  }
}
