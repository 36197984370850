<mat-toolbar class="sub_header d-flex flex-row justify-content-between flex-wrap">
  <div class="col-lg-8 col-12">
    <h2>Statistiques et évolutions</h2>

    <div class="form-group">
      <div class="input-group">
        <input
          #centerInput
          [formControl]="inputTreeControl"
          [matAutocomplete]="place"
          class="form-control"
          placeholder="Centre">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary form-control" (click)="openModal()">
            <mat-icon>place</mat-icon>
          </button>
        </div>
      </div>

      <mat-autocomplete #place="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredPlaceList | async" [value]="item.place">
          {{item.place.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="form-group">
      <mat-chip-listbox>
        <mat-chip-option
          (removed)="remove(place)"
          *ngFor="let place of choixPlaceNameList"
          [removable]="removable"
          [selectable]="selectable">
          {{place}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>

  <div class="dashboard-date-picker d-flex flex-row align-items-center col-lg-4 justify-content-center">
    <mat-form-field>
      <input [matDatepicker]="picker" [max]="maxDate" [min]="minDate" matInput>
      <mat-datepicker #picker (monthSelected)="onMonthSelect($event, picker)" startView="multi-year"></mat-datepicker>
    </mat-form-field>
    <button (click)="goToLastMonth()" mat-mini-fab>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button (click)="picker.open()" mat-button>{{displayDate | date : 'MMMM yyyy':'Europe/Paris':'fr-FR' | titlecase}}</button>
    <button (click)="goToNextMonth()" mat-mini-fab>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</mat-toolbar>
